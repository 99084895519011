<template>
    <div class="flex w-full flex-col gap-y-4">
        <div class="flex w-full justify-between">
            <div class="flex flex-col">
                <span
                    class="text-lg font-medium"
                    v-text="data.customer_firstname + ' ' + data.customer_lastname"
                />
                <span
                    class="-mt-1 text-base font-medium text-muval-gray-2"
                    v-text="'#' + data.ref"
                />
            </div>
            <Lozenge
                :class="[textStyle, bgStyle]"
                class="mt-1"
                >{{ data.move_status }}</Lozenge
            >
        </div>
        <div class="flex flex-col">
            <span class="font-medium">Contact info</span>
            <span v-text="data.customer_email" />
            <span v-text="data.customer_phone" />
        </div>
        <div class="flex flex-col">
            <span class="font-medium">Pickup</span>
            <span v-text="data.pickup_address_formatted" />
        </div>
        <div class="flex flex-col">
            <span class="font-medium">Delivery</span>
            <span v-text="data.delivery_address_formatted" />
        </div>
        <hr />
        <div class="flex flex-col">
            <span class="font-medium">Assignee</span>
            <template v-if="assignee">
                <div class="mt-1 flex items-center gap-x-2">
                    <img
                        :src="assignee.avatar"
                        class="size-8 rounded-full"
                    />
                    <span v-text="assignee.fullname" />
                </div>
            </template>
            <template v-else>
                <div class="mt-1 flex items-center gap-x-2">
                    <SvgIcon
                        name="system-user-filled"
                        class="size-8 text-muval-gray-4"
                    />
                    <span>Unassigned</span>
                </div>
            </template>
        </div>
    </div>
</template>
<script setup>
const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
});

const assignee = computed(() => {
    if (props.data.agent_id && agents.value.length) {
        return agents.value.find((a) => a.id === props.data.agent_id);
    }
    return null;
});

const { textStyle, bgStyle } = useStatusColours(props.data.move_status);

const { agents } = useAgents();
</script>
