<template>
    <RouterView
        v-if="isMuvalUser"
        v-slot="{ Component, route }"
        name="agent"
    >
        <component
            :is="Component"
            v-if="route.meta?.fullscreen"
        />
        <div
            v-else
            class="max-w-screen flex h-screen w-full"
        >
            <aside
                v-if="showLayout"
                class="border-r"
            >
                <NavigationController />
            </aside>
            <div class="flex w-0 flex-1 flex-col gap-y-4">
                <NewTopHeader
                    v-if="showLayout"
                    class="mx-auto -mb-1 max-w-[1920px] px-4 lg:px-8"
                />
                <main class="h-[calc(100vh-88px)] flex-1 overflow-auto">
                    <div class="mx-auto max-w-[1920px] first:mt-1 last:mb-8 lg:px-8">
                        <component :is="Component" />
                    </div>
                </main>
            </div>
            <NotificationSlideOver
                v-if="userStore.isAuthenticated"
                class="z-40"
            />
        </div>
    </RouterView>
    <RouterView
        v-else-if="isRelocationsUser"
        v-slot="{ Component, route }"
        name="relocations"
    >
        <component
            :is="Component"
            v-if="route.meta?.fullscreen"
        />
        <div
            v-else
            class="max-w-screen flex h-screen w-full"
        >
            <aside
                v-if="showLayout"
                class="border-r"
            >
                <NavigationController />
            </aside>
            <div class="flex w-0 flex-1 flex-col gap-y-4">
                <NewTopHeader
                    v-if="showLayout"
                    class="mx-auto max-w-[1920px] px-4 lg:px-8"
                />
                <main class="h-[calc(100vh-88px)] flex-1 overflow-auto">
                    <div class="mx-auto max-w-[1920px] first:mt-1 last:mb-8 lg:px-8">
                        <component :is="Component" />
                    </div>
                </main>
            </div>
            <NotificationSlideOver
                v-if="userStore.isAuthenticated"
                class="z-40"
            />
        </div>
    </RouterView>
    <RouterView
        v-else
        v-slot="{ Component, route }"
    >
        <component
            :is="Component"
            v-if="route.meta?.fullscreen"
        />
        <div
            v-else
            class="max-w-screen flex h-screen w-full"
        >
            <aside
                v-if="showLayout"
                class="border-r"
            >
                <NavigationController />
            </aside>
            <div class="flex w-0 flex-1 flex-col gap-y-4">
                <NewTopHeader
                    v-if="showLayout"
                    class="mx-auto -mb-1 max-w-[1920px] px-4 lg:px-8"
                />
                <main class="h-[calc(100vh-88px)] flex-1 overflow-auto">
                    <div class="mx-auto max-w-[1920px] first:mt-1 last:mb-8 lg:px-8">
                        <component :is="Component" />
                    </div>
                </main>
            </div>
        </div>
    </RouterView>
    <ToastNotification
        v-if="showLayout"
        class="z-50"
    />
</template>
<script setup>
const appStore = useAppStore();
const userStore = useUserStore();

const route = useRoute();

const isMuvalUser = computed(() => {
    return userStore.hasAnyRole(['MUVAL_AGENT', 'MUVAL_ADMIN', 'MUVAL_TRIAGE', 'MUVAL_COMPANY', 'SUPER']);
});

const isRelocationsUser = computed(() => {
    return userStore.hasAnyRole(['RELOCATIONS_MANAGER']);
});

const showLayout = computed(() => {
    return (
        userStore.isAuthenticated &&
        route.name !== 'Login' &&
        route.name !== 'Register' &&
        route.name !== 'Update your preferences'
    );
});

onMounted(async () => {
    appStore.setDeviceType();
    await appStore.initGoogle();
});
</script>
<style>
/* width */
::-webkit-scrollbar {
    width: 5px;
    padding: 5px;
    border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
    padding: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    padding: 5px;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #c4c458;
}
</style>
