<template>
    <div class="flex h-full min-h-[325px] w-full flex-col">
        <template v-if="!context.context.search"> </template>
        <template v-else-if="context.context.searching">
            <div
                v-for="i in 5"
                :key="i"
                class="flex h-14 w-full gap-x-2 p-2"
            >
                <div class="size-10 shrink-0 animate-pulse rounded-md bg-muval-gray-5" />
                <div class="flex w-full flex-col justify-between py-1">
                    <div class="flex h-3 w-full gap-x-4">
                        <div class="h-3 w-40 animate-pulse bg-muval-gray-5" />
                    </div>
                    <div class="flex h-3 w-full gap-x-4">
                        <div class="h-3 w-24 animate-pulse bg-muval-gray-5" />
                        <div class="h-3 w-24 animate-pulse bg-muval-gray-5" />
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <template v-if="context.context.results.length === 0">
                <span class="m-auto grow text-muval-gray-1"> No results found, try a different search term. </span>
            </template>
            <div
                v-else
                class="grid w-full grid-cols-12 gap-x-2"
            >
                <div class="col-span-5 flex flex-col">
                    <div
                        v-for="result in context.context.results"
                        :key="result.hasOwnProperty('ref') ? 'o_' + result.id : 'c_' + result.id"
                    >
                        <Command.OrderResult
                            v-if="result.hasOwnProperty('ref')"
                            :data="result"
                            @focus="handleFocused(result)"
                            @mouseover="handleHovered(result)"
                            @mouseleave="handleHovered(null)"
                        />
                        <Command.CompanyResult
                            v-else-if="result.hasOwnProperty('owner_id')"
                            :data="result"
                            @focus="handleFocused(result)"
                            @mouseover="handleHovered(result)"
                            @mouseleave="handleHovered(null)"
                        />
                    </div>
                    <button
                        class="mt-2 grid h-8 w-full place-items-center rounded-md bg-muval-gray-5 text-muval-gray-2 focus:outline-none focus:ring-2 focus:ring-muval-brand"
                        @keydown.enter="goToSearch"
                        @click="goToSearch"
                    >
                        View all
                    </button>
                </div>
                <div class="col-span-7 grid px-4">
                    <template v-if="detailItem">
                        <Command.OrderDetail
                            v-if="detailItem.hasOwnProperty('ref')"
                            :key="detailItem.hasOwnProperty('ref') ? 'o_' + detailItem.id : 'c_' + detailItem.id"
                            :data="detailItem"
                        />
                        <Command.CompanyDetail
                            v-else-if="detailItem.hasOwnProperty('owner_id')"
                            :key="detailItem.hasOwnProperty('ref') ? 'o_' + detailItem.id : 'c_' + detailItem.id"
                            :data="detailItem"
                        />
                    </template>
                </div>
            </div>
        </template>
    </div>
</template>
<script setup>
import { Command } from '@components/command';

const context = inject('context');
const userStore = useUserStore();
const router = useRouter();

function goToSearch() {
    if (context.context.search) {
        userStore.search = context.context.search;
        router.push({ name: 'Search' });
        context.context.handleClose();
    }
}

const focusedItem = ref();
const hoveredItem = ref();

const detailItem = computed(() => focusedItem.value ?? hoveredItem.value);

watch(
    () => context.context.search,
    () => {
        focusedItem.value = null;
        hoveredItem.value = null;
    },
);

function handleFocused(e) {
    focusedItem.value = e;
}

function handleHovered(e) {
    hoveredItem.value = e;
}
</script>
