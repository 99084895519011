import { notify } from '@kyvg/vue3-notification';
import { defineStore } from 'pinia';
import { useMoveStore, useOrderStore } from '@store';

export const useMatchStore = defineStore('match', () => {
    const timeoutId = ref(null);
    const loading = ref(false);
    const queueProcessing = ref(false);
    const data = ref({});
    const collection = ref([]);
    const listener = ref(null);

    const orderStore = useOrderStore();
    const moveStore = useMoveStore();

    async function retrieveCollection(moveId = moveStore.getMoveId) {
        console.log('retrieveCollection', moveId);
        loading.value = true;
        const {
            data: { data: response },
        } = await window.axios.get(`/moves/${moveId}/matches?sort=order&limit=999`);

        console.log('retrieveCollection', response);
        ignoreCollectionUpdate(() => {
            collection.value = response;
        });
        loading.value = false;
        return response;
    }

    async function retrieveMatch(matchId, moveId) {
        try {
            const {
                data: { order: response },
            } = await window.axios.get(`/moves/${moveId}/matches/${matchId}`);
            return response;
        } catch (e) {
            console.error('Matches store :: retrieveMatch', e);
            return new Error('Failed to retrieve match');
        }
    }

    async function updateMatch(matchId, moveId, data) {
        return await window.axios.patch(`/moves/${moveId}/matches/${matchId}`, data);
    }

    async function retrigger(moveId, matchMode = '') {
        try {
            loading.value = true;
            const response = await window.axios.post(`/moves/${moveId}/matches/trigger?match_mode=${matchMode}`);
            ignoreCollectionUpdate(() => {
                collection.value = response?.data?.data ?? [];
            });
            await orderStore.getTotals();
            return response;
        } catch {
            ignoreCollectionUpdate(() => {
                collection.value = [];
            });
            return [];
        } finally {
            loading.value = false;
        }
    }

    async function listenForMatchUpdates(orderId) {
        if (!orderId) {
            return;
        }

        console.log(`Starting to listen for order match updates on: MoveMatchingDone.${orderId}`);

        listener.value = window.echo
            .private(`orders.${orderId}`)
            .listen('MoveMatchingStartEvent', async (notification) => {
                console.log('MoveMatchingStartEvent', notification);

                notify({
                    title: 'Matching running',
                    group: 'app_info',
                    text: '',
                });

                this.queueProcessing = true;

                if (this.timeoutId) {
                    clearTimeout(this.timeoutId);

                    console.log('clearing pending timeout');

                    this.timeoutId = null;
                }

                this.timeoutId = setTimeout(() => {
                    if (this.queueProcessing) {
                        console.log('queue processing active 20s after start event - clearing and refreshing');

                        this.queueProcessing = false;

                        const moveStore = useMoveStore();

                        this.retrieveCollection(moveStore.getMoveId);
                    } else {
                        console.log('queue processing cleared 20s after start event - no action');
                    }
                }, 20000);
            });

        listener.value = window.echo
            .private(`orders.${orderId}`)
            .listen('MoveMatchingProcessingEvent', async (notification) => {
                console.log('MoveMatchingProcessingEvent', notification);

                notify({
                    title: 'Matching running',
                    group: 'app_info',
                    text: '',
                });

                this.queueProcessing = true;

                if (this.timeoutId) {
                    console.log('clearing pending timeout');

                    clearTimeout(this.timeoutId);

                    this.timeoutId = null;
                }

                this.timeoutId = setTimeout(() => {
                    if (this.queueProcessing) {
                        console.log('queue processing active 5s after processing event - clearing and refreshing');

                        this.queueProcessing = false;

                        const moveStore = useMoveStore();

                        this.retrieveCollection(moveStore.getMoveId);
                    } else {
                        console.log('queue processing cleared 5s after processing event - no action');
                    }
                }, 5000);
            });

        listener.value = window.echo
            .private(`orders.${orderId}`)
            .listen('MoveMatchingDoneEvent', async (notification) => {
                console.log('MoveMatchingDoneEvent', notification);

                notify({
                    title: 'Matching complete',
                    group: 'app_success',
                    text: '',
                });

                await retrieveCollection(moveStore.getMoveId);

                this.queueProcessing = false;
            });
    }

    function stopListeningForMatchUpdates() {
        if (timeoutId.value) {
            console.log('clearing pending timeout');

            clearTimeout(timeoutId.value);

            timeoutId.value = null;
        }

        console.log('... terminating listen for order match updates.');

        listener.value = null;
    }

    // MATCH FORM

    async function retrieveAccessOptions() {
        const {
            data: { data: data },
        } = await window.axios.get('access-tags?type=pricing&with=interstatePriceItem');
        return data;
    }

    async function retrieveInventoryOptions() {
        const {
            data: { data: data },
        } = await window.axios.get('inventory-options?type=pricing&with=interstatePriceItem');
        return data;
    }

    async function customQuoteCalculate(move_id, values) {
        const { data: response } = await window.axios.post(`/moves/${move_id}/custom-quotes/calculate`, values);
        return response;
    }

    async function calculateSurcharge(company_id, type, interstate_price_item_id, value) {
        const response = await window.axios.post(`moves/${moveStore.getMoveId}/calculate-surcharge`, {
            company_id,
            type,
            interstate_price_item_id,
            value: interstate_price_item_id == 3 ? 1 : value, // if id is 3, it is the elevator and always has a value of 1
        });
        return response.data;
    }

    async function retrieveMoveAdditionalPriceItemsAccess(move_id) {
        const response = await window.axios.get(`moves/${move_id}/custom-quotes/additional-price-items?type=ACCESS`);
        console.log('retrieveMoveAdditionalPriceItemsAccess', response);
        return response.data;
    }

    async function retrieveMoveAdditionalPriceItemsInventory(move_id) {
        const response = await window.axios.get(`moves/${move_id}/custom-quotes/additional-price-items?type=INVENTORY`);
        return response.data;
    }

    async function createMatch(move_id, values) {
        return await window.axios.post(`/moves/${move_id}/custom-quotes`, values);
    }

    // handle reordering
    const { ignoreUpdates: ignoreCollectionUpdate } = watchIgnorable(
        () =>
            collection.value.map((item, index) => {
                return {
                    match_id: item.id,
                    order: index,
                };
            }),
        async (newValue, oldValue) => {
            if (oldValue.length === 0) return;
            try {
                const response = await window.axios.post(`/moves/${moveStore.getMoveId}/matches/reorder`, {
                    reorder: newValue,
                });
                ignoreCollectionUpdate(() => {
                    collection.value = response.data.data;
                });
                notify({
                    title: 'Matches reordered successfully',
                    group: 'app_success',
                    text: '',
                });
            } catch (e) {
                console.error('Matches store :: reordering', e);
            }
        },
    );

    // bulk update
    async function batchUpdate(data) {
        const response = await window.axios.patch(`/moves/${moveStore.getMoveId}/matches/batch`, data);
        ignoreCollectionUpdate(() => {
            collection.value = response.data.data;
        });
        notify({
            title: 'Matches updated successfully',
            group: 'app_success',
            text: '',
        });
    }

    // review tender
    async function reviewTender(matchId, data) {
        const { muval_fee, booking_fee, space_total, deposit } = data;

        const response = await window.axios.patch(`/moves/${moveStore.getMoveId}/matches/${matchId}/review`, {
            muval_fee,
            booking_fee,
            space_total,
            deposit,
        });

        let index = collection.value.findIndex((match) => match.id === response.data.move_match.id);

        if (index !== -1) {
            ignoreCollectionUpdate(() => {
                collection.value[index] = response.data.move_match;
            });
        }

        return response.data.move_match;
    }

    // FOR FORMATTING ADDITIONAL PRICE ITEMS FROM RESOURCE
    function formatAdditionalPriceItems(items) {
        return {
            access: items.filter((item) => item.access_type)?.map(formatItem),
            inventory: items.filter((item) => item.access_type === null)?.map(formatItem),
        };

        function formatItem(item) {
            switch (item.access_type) {
                case null: // inventory
                    return {
                        interstate_price_item_id: item.interstatePriceItem.id,
                        inventory_option_id: item.priceable.inventory_option.id,
                        qty: item.value,
                        cost: item.cost.amount,

                        // meta info not needed for submission
                        fixed: true, // for items that are imported from the move
                    };
                default: // pickup/delivery
                    return {
                        type: item.access_type,
                        interstate_price_item_id: item.interstatePriceItem.id,
                        value: item.value,
                        cost: item.cost.amount,

                        fixed: true, // for items that are imported from the move
                    };
            }
        }
    }

    const getCollection = computed(() => collection.value);
    const getCollectionCount = computed(() => collection.value.length);

    return {
        loading,
        queueProcessing,
        data,
        collection,
        listener,
        retrieveCollection,
        retrieveMatch,
        updateMatch,
        retrigger,
        listenForMatchUpdates,
        stopListeningForMatchUpdates,
        retrieveAccessOptions,
        retrieveInventoryOptions,
        customQuoteCalculate,
        calculateSurcharge,
        retrieveMoveAdditionalPriceItemsAccess,
        retrieveMoveAdditionalPriceItemsInventory,
        createMatch,
        formatAdditionalPriceItems,
        batchUpdate,
        reviewTender,
        getCollection,
        getCollectionCount,
    };
});
